@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

body {
  overflow-x: hidden;
}
h1 {
  font-size: 100vh;
}

* {
  font-family: 'Inter', sans-serif;
}

.color-1 {
  background-color: #112d49;
}

.logo-svg {
  content: url('../public/svg/logo.svg');
  filter: invert(100%) brightness(10000%);
  cursor: pointer;
}

.logo-svg-max {
  content: url('../public/svg/logo-max.svg');
  filter: invert(100%) brightness(10000%);
}

.kld::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  background-size: cover;
  opacity: 40%;
}
@media (min-width: 992px) {
  .custom-drop-item {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .custom-nav-link {
    padding-top: 16px;
    padding-bottom: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .custom-nav-link::after {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    content: '';
    border-bottom: solid 1px rgb(233, 215, 77);
    transform: scaleX(0);
    transition: ease 1000ms;
  }
  .custom-nav-link:hover::after {
    transform: scale(1);
  }
}
.custom-nav-link,
.custom-drop-item {
  position: relative;
  color: white;
  transition: linear 500ms;
}

.custom-drop {
  border-top: solid 1px rgb(233, 215, 77);
  transition: all linear 500ms;
}

.custom-nav-link:hover,
.custom-drop-item:hover {
  color: rgb(233, 215, 77) !important;
}

.container-main {
  margin-top: 120px;
}

.main-head_1 {
  font-size: calc(16px + 1vw);
}

.main-head_2 {
  font-size: calc(10px + 1vw);
  margin-bottom: 50px;
}

.button-main {
  background-color: #112d49;
  border-color: aliceblue;
}

.button-main:hover {
  background-color: white;
  border-color: #112d49;
  color: #112d49;
}

.button-main--info {
  color: #112d49;
}

.content-off {
  opacity: 0;
  transform: translate(0px, 10px);
  visibility: hidden;
}

.show {
  opacity: 1;
  transform: translate(0px, 0px);
  visibility: inherit;
  transition: ease 0.75s;
  transition-delay: 0.1s;
}
.main-card {
  position: relative;
}

.about-off {
  opacity: 0;
}
.about-img {
  position: relative;
  z-index: 2;
  left: -10px;
  transition: ease 1.5s;
  transition-delay: 0.1s;
}

.about-description {
  position: relative;
  right: -10px;
  z-index: 1;
  padding: 60px 90px;
  background-color: #e2ddd9;
  transition: ease 1.5s;
  transition-delay: 0.1s;
}

.about-animation .about-img {
  left: 30px;
}
.about-animation .about-description {
  right: 30px;
}

.about-button {
  text-decoration: none;
  color: black;
  transition: ease 0.5s;
  border: 0;
  border-radius: 0;
  border-bottom: solid 2px;
  border-color: #0000;
}

.about-button:hover {
  color: black;
  border-color: black;
}

.service-card {
  position: relative;
  height: 256px;
  border-width: 0px !important;
  border-radius: 20px;
  margin-bottom: 25px;
  overflow: hidden;
  cursor: pointer;
}

.main-service-card {
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-card-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(120%);
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1.5s;
}

.service-card-name {
  position: absolute;
  bottom: 15px;
  left: -30px;
  box-sizing: content-box;
  background-color: #112d49;
  border-radius: 14px;
  padding: 3px 0;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1.5s;
}

.service-card-text {
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1rem;
  margin: auto 15px auto 50px;
}

.service-card-img:hover {
  transform: translate(-50%, -50%) scale(100%);
  filter: brightness(70%);
}

.service-card:hover .service-card-name {
  left: -10px;
}

.separator {
  border: 0;
  border-bottom: solid 1px #e2ddd9;
}

.iframe-map {
  width: 100%;
}

.button-footer {
  text-transform: uppercase;
  background-color: inherit;
  border: 0;
  margin: 0;
  max-width: 100%;
}

.button-footer:hover {
  background-color: inherit;
}
.footer-icon {
  font-size: 2em;
  margin-right: 20px;
  cursor: pointer;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
}
.footer-icon:hover {
  color: #e9b488;
}
